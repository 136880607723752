<template>
  <div class="container pt-3">
    <div v-if="haveCashback" class="claim">
      <div class="float-left">
        <span class="responsiveElipsis">{{ cashbackAmount }} {{ currency }}</span>
      </div>
      <div class="float-right">
        <button @click="this.claimCashback()" v-bind:class="cashbackClaiming == true ? 'txtdisabled' : ''"
          title="Claim Cashback">
          CLAIM
        </button>
        <button title="About Cashback" data-toggle="modal" data-target="#exampleModalLive"
          style="margin-left: 5px; padding: 5px 15px" @click="showPopup">
          <img src="/images/info.svg" style="width: 20px; height: 20px" />
        </button>
      </div>
      <div class="clearfix"></div>
    </div>
    <div v-if="haveCashback" class="text-start text-hint ps-1 pt-1 mb-3">
      Instant Cashback
    </div>
    <div class="row">
      <div class="col ballsHolder m-0 p-0" ref="ballsHolder">
        <div class="plinkoBoardHolder" id="plinkoBoardHolder">
          <canvas id="plinkoBoardCanvas" class="pt-2"></canvas>
        </div>
        <div class="clearfix"></div>
        <div class="gameBet">
          <button class="btn plinkoselectrow" :class="plinkoGame.rows == 8 ? 'plinkoselectactive' : ''"
            :disabled="autobetSettings.state" @click="updateRow(8)">
            8
          </button>
          <button class="btn plinkoselectrow" :class="plinkoGame.rows == 12 ? 'plinkoselectactive' : ''"
            :disabled="autobetSettings.state" @click="updateRow(12)">
            12
          </button>
          <button class="btn plinkoselectrow" :class="plinkoGame.rows == 16 ? 'plinkoselectactive' : ''"
            :disabled="autobetSettings.state" @click="updateRow(16)">
            16
          </button>

          <button class="btn plinkoselect" :class="
            plinkoGame.currentCoefIndex == 0 ? 'plinkoselectactive' : ''
          " :disabled="autobetSettings.state" @click="updateCoeffs(0)">
            LOW
          </button>
          <button class="btn plinkoselect" :class="
            plinkoGame.currentCoefIndex == 1 ? 'plinkoselectactive' : ''
          " :disabled="autobetSettings.state" @click="updateCoeffs(1)">
            MED
          </button>
          <button class="btn plinkoselect" :class="
            plinkoGame.currentCoefIndex == 2 ? 'plinkoselectactive' : ''
          " :disabled="autobetSettings.state" @click="updateCoeffs(2)">
            HIGH
          </button>
        </div>
        <div class="buttons mb-3">
          <div class="plinkoconnect" v-if="state.buttonstate == 'unlock'">
            <button class="betbutton" @click="plinkoGame.unlock()">UNLOCK</button>
          </div>
          <div class="clearfix"></div>
          <div class="plinkoconnect" v-if="state.buttonstate == 'connect'">
            <button class="betbutton" @click="plinkoGame.connect()">
              CONNECT
            </button>
          </div>
          <div class="clearfix"></div>

          <div class="gameBet" v-if="state.buttonstate == 'play'">
            <div class="text-start text-hint ps-1">
              Min Bet {{ minBet }} / Max Bet {{ maxBet }} {{ currency }}
            </div>
            <input class="betinput" type="number" v-model="betAmount" :disabled="autobetSettings.state" />
            <button @click="betAmount = gameHelper.modifyBet(betAmount, 1)" class="btn betModifiers"
              :disabled="autobetSettings.state">
              x2
            </button>
            <button @click="betAmount = gameHelper.modifyBet(betAmount, 2)" class="btn betModifiers"
              :disabled="autobetSettings.state">
              x/2
            </button>
            <div></div>
            <div class="text-start text-hint ps-1 pt-1" v-if="betAmount > 0 && haveCashback">
              Cashback amount {{ calculateCashback(betAmount) }}
              {{ currency }}
            </div>
            <div>
              <button v-if="!autobetSettings.state" class="betbutton" @click="makeBet"
                :disabled="!state.gameBet || autobetSettings.state" ref="makeBet">
                <span v-if="state.gameBetloader" class="spinner-border text-warning me-2" role="status"
                  aria-hidden="true"></span>
                <span>BET{{ betWaitCountMsg }}</span>
              </button>

              <button v-if="autobetSettings.state" class="betbutton" @click="
                autobetSettings = gameHelper.autobetStop(autobetSettings)
              ">
                <span class="spinner-border text-warning me-2" role="status" aria-hidden="true"></span>
                <span>STOP</span>
              </button>

              <Autobet @autobet="autobetStart" :game="state" />
            </div>
            <div class="clearleft"></div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="gameBet pt-1">
          <div class="betstab" v-bind:class="
            historyManager.currentBetHistoryList == 'MY'
              ? 'betstabactive'
              : ''
          " @click="historyManager.setBetHistoryList('MY')">
            MY
          </div>
          <div class="betstab" v-bind:class="
            historyManager.currentBetHistoryList == 'ALL'
              ? 'betstabactive'
              : ''
          " @click="historyManager.setBetHistoryList('ALL')">
            ALL
          </div>
        </div>

        <div class="clearfix"></div>
        <div class="betstabbottomline"></div>

        <TokenHistoryTable :data="historyManager.betHistory" />
      </div>
    </div>
    <Popup v-show="wrongNetwork.state" @close="closePopup" :title="wrongNetwork.title" :body="wrongNetwork.body"
      fontSize="normal" />
    <Popup v-show="isPopupVisible" @close="closePopup" title="INSTANT CASHBACK RULES" :body="getCashbackText()" />

  </div>
</template>

<script>
import PlinkoGame from "../index.js";
import PlinkoBoard from "./PlinkoBoard.js";

import Popup from "@/components/Popup.vue";
import CashbackHelper from "../../../services/cashbackhelper.js";
import Cashback from "../../../services/Cashback.min.json";

import Token from "../../../services/Token.min.json";
import TokenHelper from "../../../services/tokenhelper.js";


import gameHelper from "../../helpers/gameHelper.js";
import Autobet from "../../components/Autobet";
import TokenHistoryTable from "../../components/TokenHistoryTable";

import HistoryManager from "../../../services/historymanager";
import Plinko from "../contracts/Plinko2.min.json";

export default {
  name: "GamePlay",
  components: {
    TokenHistoryTable,
    Autobet,
    Popup
  },
  data() {
    const state = {
      plinkomessage: true,
      plinkowin: false,
      plinkoWinAmount: "0.0",
      buttonstate: "connect",
      gameBet: false,
      gameBetloader: false,

      plinkoMessageStr: "Connecting...",
      game: "plinko",
    };

    var plinkoGame = null;
    var plinkoBoard = null;
    var historyManager = null;
    var cashbackHelper = null;
    var tokenHelper = null
    return {
      plinkoBoard,
      plinkoGame,
      historyManager,
      cashbackHelper,
      tokenHelper,
      state,
      haveCashback: true,
      cashbackAmount: 0,
      cashbackClaiming: true,
      isPopupVisible: false,
      autobetSettings: false,
      gameHelper: new gameHelper(state.game),
      betAmount: this.$store.getters.getGameLimits[state.game].min,
      minBet: this.$store.getters.getGameLimits[state.game].min,
      maxBet: this.$store.getters.getGameLimits[state.game].max,
      currency: this.$store.getters.getCurrency,
      betWaitCount: 0,
      betWaitCountMsg: "",
      wrongNetwork: {
        state: false,
        title: 'Wrong Network',
        body: `You are trying to play with {{url network}} but you are connected to {{user network}}. 
                Please connect to {{url networ}} and click reload button below
                <div class="text-center"><a class="btn btn-secondary btn-sm mt-3">Reload</a></div>`,
      }
    };
  },
  unmounted() {
    this.plinkoGame.destroy();
    this.cashbackHelper.destroy();
    this.historyManager.destroy()
    this.tokenHelper.destroy()
  },
  beforeMount() {
    this.historyManager = new HistoryManager(this.historyHandler, this.state.game, Plinko);

    this.plinkoGame = new PlinkoGame(this.messageHandler, Plinko, this.state.game, 16);
    this.plinkoGame.historyManager = this.historyManager;

    this.plinkoBoard = new PlinkoBoard(
      this.messageHandler,
      this.plinkoGame.rows
    );
    this.plinkoBoard.setCoeffs(
      this.plinkoGame.rows,
      this.plinkoGame.currentCoefIndex,
      this.plinkoGame.coefs
    );
    this.plinkoGame.init();
    this.cashbackHelper = new CashbackHelper(this.messageHandler, Cashback);
    this.tokenHelper = new TokenHelper(this.messageHandler, Token);
    this.haveCashback = this.$store.state.user.currency.cashback;
    this.$store.getters.getConfig.games[this.state.game].contract = this.plinkoGame.address
  },
  mounted() {
    this.$store.commit("setEnableFast", true);
    this.boardItems;
    this.matchSizes();
    this.plinkoBoard.init();

    this.$nextTick(function () {
      window.addEventListener("resize", this.matchSizes);
    });

  },
  watch: {
    windowSize: function () {
      this.matchSizes();
    },
    "state.plinkoMessageStr": function (gameMessage) {
      if (gameMessage != "") {
        this.$toast.show(gameMessage);
      }
      this.state.plinkoMessageStr = "";
    },
    "$store.state.user.currency": function () {
      this.setCurrency(this.$store.state.user.currency);
    },
  },
  methods: {
    historyHandler(obj, list) {
      console.log(obj)
      if (this.plinkoGame.playeraddress != obj.address) {
        this.historyManager.addBetHistory(obj, list)
      }

    },
    setCurrency(_currency) {
      this.currency = _currency.name.toUpperCase();
      this.haveCashback = _currency.cashback;

      this.plinkoGame.setCurrency(_currency);

      this.state.buttonstate = "play";
      this.minBet = this.$store.getters.getGameLimits[this.state.game].min;
      this.maxBet = this.$store.getters.getGameLimits[this.state.game].max;
    },
    makeBet() {
      if (!this.gameHelper.verify("betAmount", this.betAmount)) {
        return this.$toast.error(
          this.gameHelper.getMessage("invalid_bet_amount")
        );
      }
      this.plinkoGame.betHandler(this.betAmount);
    },
    autobetStart(settings) {
      this.autobetSettings = this.gameHelper.autobetStart(settings);
      return this.makeBet();
    },
    autobetProcess(result) {
      this.autobetSettings = this.gameHelper.autobet(
        this.autobetSettings,
        result
      );
      if (this.autobetSettings.state) {
        //start new raund
        this.betAmount = this.autobetSettings.bet
          ? this.autobetSettings.bet.current
          : 0;
        return this.makeBet();
      }
      //finish autobet
      this.autobetSettings = this.gameHelper.autobetStop(this.autobetSettings);
      this.gameHelper.sleep(this.$store.getters.getConfig.games[this.state.game].delay);
      return (this.betAmount = this.autobetSettings.bet.initial);
    },
    updateCoeffs(index) {
      this.plinkoGame.setCoeffs(index);
      this.plinkoBoard.setCoeffs(
        this.plinkoGame.rows,
        this.plinkoGame.currentCoefIndex,
        this.plinkoGame.coefs
      );
    },
    updateRow(rows) {
      this.plinkoGame.setRows(rows);
      this.plinkoBoard.setCoeffs(
        this.plinkoGame.rows,
        this.plinkoGame.currentCoefIndex,
        this.plinkoGame.coefs
      );
    },

    matchSizes() {
      this.plinkoBoard.init();
    },
    messageHandler(obj) {
      //betWaitCount
      if (obj.type == "limits") {
        window.Logger.log(obj)
        this.betAmount = obj.min;
        this.minBet = obj.min;
        this.maxBet = obj.max;
        this.$store.getters.getGameLimits[this.state.game].min = this.minBet;
        this.$store.getters.getGameLimits[this.state.game].max = this.maxBet;
        this.state.gameBet = true;
        return;
      }
      //
      if (obj.type == "tokennotalowed") {
        this.state.buttonstate = "unlock";
        return;
      }
      if (obj.type == "tokenalowed") {
        this.state.buttonstate = "play";
        return;
      }

      //type=balance modis roca balasi ganaxlda
      if (obj.type == "balance") {
        let balance = parseFloat(obj.balance);
        if(obj.currency === 'TT'){
          this.$store.commit("setBalance", balance.toFixed(2));
        }else{
          this.$store.commit("setBalance", balance.toFixed(5));
        }
        this.$store.commit("setCurrency", obj.currency);

        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }

      if (obj.type == "cashbackadd") {
        this.cashbackAmount = obj.balance;
        if (parseFloat(this.cashbackAmount) > 0) {
          this.cashbackClaiming = false;
        }

        return;
      }

      if (obj.type == "tokenbalance") {
        console.log(obj)
        this.$store.commit("setTokenBalance", parseFloat(obj.balance).toFixed(3));
        return;
      }

      if (obj.type == "cashbackclaim") {
        this.$toast.success("Claimed " + obj.amount + " " + this.currency, {
          duration: 3000,
        });
        this.diceGame.fetchAccount();
        this.cashbackHelper.balanceOf();
        return;
      }


      if (obj.type == "accountset") {
        if (this.$store.getters.getUserWallet != obj.msg) {
          this.$store.commit("setUserWallet", obj.msg);
        }
        this.plinkoGame.playeraddress = obj.msg;

        if (this.cashbackHelper.account == null) {
          this.cashbackHelper.onConnet(obj.msg);
          this.cashbackHelper.balanceOf();
        }
        if (this.tokenHelper.account == null) {
          this.tokenHelper.onConnet(obj.msg);
          this.tokenHelper.balanceOf();
        }
        if (
          this.$store.state.user.currency.address !=
          this.plinkoGame.tokenObj.address
        ) {
          this.setCurrency(this.$store.state.user.currency);
        }


        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }

      if (obj.type == "networkchange") {
        console.log(obj);
        var url = this.gameHelper.getRedirectUrl(this.state.game, obj.networkVersion)
        console.log(url)
        if (url != null) {
          window.location.href = url
        } else {
          var chains = this.gameHelper.getChainsList(this.state.game)
          window.Logger.log(chains)
          this.wrongNetwork.state = true
          this.wrongNetwork.body = "This game is not available on this Chain/Token<br/><br/>"
          this.wrongNetwork.body += "You can play this game on the next Cnain(Tokens)<br/><br/>"
          for (let index = 0; index < chains.length; index++) {
            const element = chains[index];
            this.wrongNetwork.body += `${element.blockchain_name}(${element.currency_codes.join(', ')})<br/><br/>`
          }
          this.wrongNetwork.body += "Please change to correct Chain/Token<br/>"
          this.wrongNetwork.body += `and click reload button below
                <div class="text-center"><a href="${window.location.href}" class="btn btn-secondary btn-sm mt-3">Reload</a></div>`


        }
        // 
        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }


      //type=bethistory modis roca beti dafiksirda obj.address obj.bet obj.win
      if (obj.type == "bethistory") {
        //console.log(obj);

        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }

      //type=error modis roca shecdoma dafiksirda
      if (obj.type == "error") {
        if (this.plinkoBoard.balls.length == 0) {
          this.state.gameBetloader = false;
        }
        if (obj.msg == 'N/A') {
          this.state.plinkoMessageStr = obj.msg;
          this.state.plinkomessage = true;
          setTimeout(
            function () {
              this.state.plinkomessage = false;
            }.bind(this),
            3000
          );
          this.autobetSettings = this.gameHelper.autobetStop(
            this.autobetSettings
          );
        } else {
          console.log("empty error message")
        }


        //this.betAmount = this.autobetSettings.bet
        //  ? this.autobetSettings.bet.current
        //  : 0;
      } else {
        this.state.plinkowin = false;
        //this.state.gameBet = false;
        this.state.buttonstate = "play";

        //type=connected modis roca metamaskze dakonektda
        if (obj.type == "connected") {
          this.$store.commit("setCurrencyEnabled", this.$store.getters.getConfig.games[this.state.game].tokens);
          this.state.plinkomessage = false;
          this.state.gameBet = true;

        }

        //type=rejected modis roca userma metamaskze rejecti misca konektze da vachvenebt konektis gilaks
        //type=notconnected  modis roca useri araa dakonektebuli da vachvenebt konektis gilaks
        //da vmalavt mesijis divs
        if (obj.type == "rejected" || obj.type == "notconnected") {
          this.state.plinkomessage = false;
          this.autobetSettings = this.gameHelper.autobetStop();
          this.state.buttonstate = "connect";
        }

        //type=betloader modis roca beti gavida blockchainze da velodebit dadasturebas, vachvenenbt preloaders
        if (obj.type == "betloader") {
          this.state.gameBetloader = true;
        }
        if (obj.type == "transactionHash") {
          this.betWaitCount++
          if (this.betWaitCount > 0) {
            this.betWaitCountMsg = "(" + this.betWaitCount + ")";
          } else {
            this.betWaitCountMsg = ""
          }
        }

        //type=betfinished modis roca userma an moigo an waago obj.win ari tanxa
        // tu tanxa=0 chveulebrivad betis knopkas vachvenebt
        // tu tanxa>0 chveulebrivad betis knopkas vachvenebt
        if (obj.type == "betfinished") {
          this.tokenHelper.balanceOf();
          if (this.plinkoBoard.balls.length == 0) {
            this.state.gameBetloader = false;
          }

          this.betWaitCount--
          if (this.betWaitCount < 0) {
            this.betWaitCount = 0
          }
          if (this.betWaitCount > 0) {
            this.betWaitCountMsg = "(" + this.betWaitCount + ")";
          } else {
            this.betWaitCountMsg = ""
          }
          if (obj.win > 0) {
            this.state.plinkowin = true;
            this.state.plinkoWinAmount = obj.win;

            //or wamshi vmalavt winis mesijs da vachvenebt betis knopkas
            this.$toast.success(
              this.gameHelper.getMessage("you_won_amount", obj.win),
              { duration: 1000 }
            );
            this.$soundManager.play({ name: "win" });


            this.state.plinkowin = false;
            this.state.gameBet = true;
          } else {
            this.state.gameBet = true;
          }
          console.log(obj)
          this.historyManager.addAllBetHistory(
            {
              address: this.plinkoGame.playeraddress,
              bet: obj.bet,
              tx_id: obj.tx_id,
              win: obj.win,
              currency: obj.currency,
            }
          );
          if (this.plinkoGame.erc20Game) {
            this.plinkoGame.erc20Game.balanceOf()
          } else {
            this.plinkoGame.fetchAccount();
          }



          //this.plinkoGame.fetchAccount();
          if (this.autobetSettings.state) {
            this.autobetProcess(obj);
          }
        }
        if (obj.type == "betresult") {
          this.plinkoBoard.setBet(obj);
        }

        if (obj.type == "betbutton") {
          this.state.gameBet = true;
        }
      }
    },
    claimCashback() {
      this.cashbackClaiming = true;
      this.cashbackHelper.claim();
    },
    calculateCashback(betAmount) {
      if (betAmount < 3000) {
        return (betAmount * 50) / 10000;
      } else if (betAmount < 5000) {
        return (betAmount * 60) / 10000;
      } else if (betAmount < 10000) {
        return (betAmount * 70) / 10000;
      } else {
        return (betAmount * 80) / 10000;
      }
    },
    showPopup() {
      this.isPopupVisible = true;
    },
    closePopup() {
      this.isPopupVisible = false;
      this.wrongNetwork.state = false
    },
    getCashbackText() {
      return `<table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Bet amount</th>
                    <th scope="col">Cashback</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>= 10 000</td>
                    <td>0.8%</td>
                  </tr>
                  <tr>
                    <td>< 10 000</td>
                    <td>0.7%</td>
                  </tr>
                  <tr>
                    <td>< 5 000</td>
                    <td>0.6%</td>
                  </tr>
                  <tr>
                    <td>< 3 000</td>
                    <td>0.5%</td>
                  </tr>
                </tbody>
              <table>

              Example table:<br>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Bet amount</th>
                    <th scope="col">Cashback</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>10 000</td>
                    <td>80</td>
                  </tr>
                  <tr>
                    <td>5 000</td>
                    <td>35</td>
                  </tr>
                  <tr>
                    <td>3 000</td>
                    <td>18</td>
                  </tr>
                  <tr>
                    <td>1 000</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td>200</td>
                    <td>1</td>
                  </tr>
                </tbody>
              </table>

              * Cashback is rewarded only on lost bets<br>
              * Cashback is rewarded for any bet size<br>
              * Cashback has no wagering terms, can be claimed instantly<br>`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #135067-->
<style scoped>
.plinkoselectrow {
  float: left;
  margin-top: 5px;
  margin-bottom: 15px;
  margin-right: 10px;
  width: 25px;
  padding: 2px 5px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  background: #262626;
  border-radius: 5px;
  font-size: 0.8rem;
  outline: none;
}

.plinkoselect {
  float: left;
  margin-top: 5px;
  margin-bottom: 15px;
  margin-right: 10px;
  width: 51px;
  padding: 2px 5px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  background: #262626;
  border-radius: 5px;
  font-size: 0.8rem;
}

.plinkoselectactive {
  color: #ffe81c;
  transition: 0.3s;
}

.plinkoBoardHolder {
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
}

.plinkoBoardCanvas {
  width: 100%;
  height: 100%;
}

.clearleft {
  clear: left;
}

.betstabbottomline {
  height: 2px;
  background: #a3a3a3;
  width: 90%;
  margin: 0 auto;
}

.betstab {
  width: 145px;
  float: left;
  margin-left: 2px;
  color: var(--tabtxt-color);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: linear-gradient(0deg, var(--tabgs-color) 0%, var(--tabge-color) 100%);
  transition: 0.3s;
}

.betstabactive {
  color: var(--tabtxtselected-color);
  background: linear-gradient(0deg, var(--tabys-color) 0%, var(--tabye-color) 100%);
  transition: 0.3s;
}

.betstabHolder {
  justify-content: center;
  align-items: center;
}

.buttons {
  margin: 60px 0px;
}

.plinkoconnect,
.gameBet {
  max-width: 300px;
  margin: 0 auto;
}

.plinkoconnect .betbutton {
  margin: 0 auto !important;
  float: none;
}

/* .plinkoconnect,
.gameBet {
  max-width: 300px;
  margin: 0 auto;
} */
.plinkomessage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #cbcbcb;
}

.plinkomessage {
  z-index: 1;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.visible {
  opacity: 1;
  transition: 0.5s;
  pointer-events: auto;
}

.hidden {
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;
}

.visiblefast {
  opacity: 1;
  pointer-events: auto;
}

.hiddenfast {
  opacity: 0;
  pointer-events: none;
}

.betinput {
  background: #d8d8d8;
  border-radius: 8px;
  font-size: 25px;
  border: none;
  text-align: center;
  padding: 5px;
  padding-top: 0px;
  max-width: 300px;
  width: 60%;
}

.betbutton {
  float: left;
  width: 60%;
  background: #262626;
  border-radius: 8px;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  padding: 5px;
  margin-top: 9px;
}

.betbutton:active {
  color: #90db00;
}

.betModifiers {
  width: 18%;
  background: #262626;
  border-radius: 8px;
  color: #ffffff;
  text-align: center;
  padding: 7px 0px;
  top: -2px;
  position: relative;
  margin-left: 5px;
}

.gameButtons {
  margin-left: 10%;
}

.autobet {
  padding: 10px 0px !important;
  top: -4px;
}

.spinner-border {
  position: relative;
  top: -3px;
  width: 1em;
  height: 1em;
}

@media (max-width: 992px) {
  .buttons {
    margin: 30px 0px;
  }
}

.claim {
  width: 100%;
  background: #fff;
  padding: 10px 12px;
}

.claim span {
  margin-top: 4px;
  display: inline-block;
}

.claim button {
  border: none;
  outline: none;
  padding: 5px 30px;
  border-radius: 5px;
  color: #333333;
}

.claim img {
  position: relative;
  top: -1px;
}
</style>
