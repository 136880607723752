import PlinkoBall from "./PlinkoBall";

export default class PlinkoBoard {
    rows = 8
    ballRadius = 5;
    pointRadius = 5;
    fontSize = 14;
    board = [];
    coeffs = []
    coeffsPositions = []
    messageHandler = null
    width = 0
    height = 0
    mode = 0
    balls = []
    ballId = 0


    constructor(_messageHandler, _rows = 8) {
        this.messageHandler = _messageHandler
        this.rows = _rows
        if (_rows == 8) {
            this.pointRadius = 5
            this.fontSize = 14;
            this.ballRadius = 5;
        }
        if (_rows == 12) {
            this.pointRadius = 4
            this.fontSize = 12;
            this.ballRadius = 4;
        }
        if (_rows == 16) {
            this.pointRadius = 3
            this.fontSize = 10;
            this.ballRadius = 3;
        }

        window.requestAnimationFrame(this.redrawCanvas.bind(this));
    }

    init() {
        this.canvas = document.getElementById("plinkoBoardCanvas");
        this.plinkoBoardHolder = document.getElementById("plinkoBoardHolder");
        //console.log("this.plinkoBoardHolder " + this.plinkoBoardHolder.clientWidth + " " + this.plinkoBoardHolder.clientHeight)
        if (this.canvas && this.plinkoBoardHolder) {
            var ratio = this.getPixelRatio();
            this.height = parseInt(this.plinkoBoardHolder.clientWidth);
            this.width = parseInt(this.plinkoBoardHolder.clientWidth);
            if (this.width > 500) {
                this.width = 500
                this.height = 500
            }
            this.canvas.width = this.width * ratio;
            this.canvas.height = this.height * ratio;
            this.canvas.style.width = this.width + "px";
            this.canvas.style.height = this.height + "px";
            this.canvas.getContext("2d").setTransform(ratio, 0, 0, ratio, 0, 0);

            this.ctx = this.canvas.getContext('2d');

            var currentLine = 3
            this.board = [];
            var linesHeight = this.height - 20
            for (let index = 0; index <= this.rows; index++) {
                var line = [];
                var maxWidth = this.width - (this.width / (this.rows + 2)) * currentLine;
                for (let i2 = 0; i2 < currentLine; i2++) {
                    var xpos = maxWidth / 2 + this.pointRadius * 2 + (this.width / (this.rows + 2)) * i2
                    var ypos = this.pointRadius * 2 + (linesHeight / this.rows) * index

                    line.push({
                        x: xpos, y: ypos,
                        sleepcolors: { color: "#292929", stroke: "#A3A3A3", gradstart: "#B6B6B6", gradend: "#A8A8A8" },
                        activecolors: { color: "#EECF16", stroke: "#EECF16", gradstart: "#FFE81C", gradend: "#FFDB1C" },
                        active: false
                    }
                    )
                }
                currentLine++
                if (index < this.rows) {
                    this.board.push(line)
                } else {
                    this.coeffsPositions = line
                }

            }
        }



    }

    getPixelRatio() {
        var ctx = document.createElement("canvas").getContext("2d"),
            dpr = window.devicePixelRatio || 1,
            bsr = ctx.webkitBackingStorePixelRatio ||
                ctx.mozBackingStorePixelRatio ||
                ctx.msBackingStorePixelRatio ||
                ctx.oBackingStorePixelRatio ||
                ctx.backingStorePixelRatio || 1;

        return dpr / bsr;
    }


    setCoeffs(_rows, _mode, _coeffs) {

        //finish all balls
        for (let i = 0; i < this.balls.length; i++) {
            const ball = this.balls[i];
            ball.betObj.type = 'betfinished'
            this.messageHandler(ball.betObj)
            ball.betObj = null
        }

        for (let i = 0; i < this.board.length; i++) {
            const row = this.board[i];
            for (let j = 0; j < row.length; j++) {
                const element = row[j];
                element.active = false;
            }
        }


        this.balls = []


        this.mode = _mode
        this.coefs = _coeffs
        var prevRows = this.rows
        this.rows = _rows
        if (_rows == 8) {
            this.pointRadius = 5
            this.fontSize = 14;
            this.ballRadius = 5;
        }
        if (_rows == 12) {
            this.pointRadius = 4
            this.fontSize = 12;
            this.ballRadius = 4;
        }
        if (_rows == 16) {
            this.pointRadius = 3
            this.fontSize = 10;
            this.ballRadius = 3;
        }

        if (prevRows != this.rows) {
            this.init()
        }
    }

    setBet(arr) {
        console.log(arr)
        var ball = new PlinkoBall()
        this.ballId++
        ball.init(this, this.ballId)
        ball.setBet(arr)
        this.balls.push(ball)
    }


    ballHandler(ball) {
        console.log(ball)
        var nballs = []
        for (let i = 0; i < this.balls.length; i++) {
            const element = this.balls[i];
            if (element.ballId != ball.ballId) {
                nballs.push(element)
            }
        }
        this.balls = nballs


        ball.betObj.type = 'betfinished'
        this.messageHandler(ball.betObj)
        ball.betObj = null


        //ball
    }




    redrawCanvas() {
        if (this.ctx == null) {
            return
        }
        window.requestAnimationFrame(this.redrawCanvas.bind(this));
        this.ctx.fillStyle = "#e8e8e8";
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        for (let i = 0; i < this.board.length; i++) {
            const row = this.board[i];
            for (let j = 0; j < row.length; j++) {
                const element = row[j];
                var colors = element.active ? element.activecolors : element.sleepcolors

                this.ctx.beginPath();
                this.ctx.arc(element.x, element.y, this.pointRadius + 1, 0, Math.PI * 2);
                this.ctx.fillStyle = colors.stroke;

                this.ctx.fill();
                var gradient = this.ctx.createLinearGradient(
                    element.x - this.pointRadius,
                    element.y,
                    element.x,
                    element.y + this.pointRadius);

                gradient.addColorStop(0, colors.gradstart);
                gradient.addColorStop(1, colors.gradend);
                this.ctx.fillStyle = gradient;

                this.ctx.beginPath();
                this.ctx.arc(element.x, element.y, this.pointRadius, 0, Math.PI * 2);
                this.ctx.fill();
            }
        }

        //draw balls
        for (let b = 0; b < this.balls.length; b++) {
            const element = this.balls[b];
            element.redrawCanvas(this.ctx)
        }


        this.ctx.font = `bold ${this.fontSize}px Arial`;
        this.ctx.textAlign = 'center';
        for (let index = 1; index < this.coeffsPositions.length - 1; index++) {
            const element = this.coeffsPositions[index];
            if (element.active) {
                this.ctx.fillStyle = element.activecolors.color;
            } else {
                this.ctx.fillStyle = element.sleepcolors.color;
            }
            this.ctx.fillText(this.coefs[index - 1], element.x, element.y);
        }
    }
    //coeffsPositions






}

