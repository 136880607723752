
import Game from "../../services/game"
import Web3 from "web3";

export default class PlinkoGame extends Game {
    coeffsTable8 = [
        [5.6, 2.1, 1.1, 1, 0.5, 1, 1.1, 2.1, 5.6],
        [13, 3, 1.3, 0.7, 0.4, 0.7, 1.3, 3, 13],
        [29, 4, 1.5, 0.3, 0.2, 0.3, 1.5, 4, 29]
    ]

    coeffsTable12 = [
        [10, 3, 1.6, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 1.6, 3, 10],
        [33, 11, 4, 2, 1.1, 0.6, 0.3, 0.6, 1.1, 2, 4, 11, 33],
        [170, 24, 8.1, 2, 0.7, 0.2, 0.2, 0.2, 0.7, 2, 8.1, 24, 170]
    ]

    coeffsTable16 = [
        [16, 9, 2, 1.4, 1.4, 1.2, 1.1, 1, 0.5, 1, 1.1, 1.2, 1.4, 1.4, 2, 9, 16],
        [110, 41, 10, 5, 3, 1.5, 1, 0.5, 0.3, 0.5, 1, 1.5, 3, 5, 10, 41, 110],
        [999, 130, 26, 9, 4, 2, 0.2, 0.2, 0.2, 0.2, 0.2, 2, 4, 9, 26, 130, 999]
    ]

    coeffsTable = []
    coefs = []
    win = 0
    result = []
    amount = 0.001
    currentCoefIndex = 1
    rows = 12

    constructor(_messageHandler, _Contract, _game, _rows = 8) {
        super(_messageHandler, _Contract, _game)
        //this.logsHandler = this.getLogsHandler


        this.rows = _rows
        if (this.rows == 8) {
            this.coeffsTable = this.coeffsTable8
        }
        if (this.rows == 12) {
            this.coeffsTable = this.coeffsTable12
        }
        if (this.rows == 16) {
            this.coeffsTable = this.coeffsTable16
        }

        this.coefs = this.coeffsTable[this.currentCoefIndex]
    }

    setCoeffs(index) {
        this.currentCoefIndex = index
        this.coefs = this.coeffsTable[this.currentCoefIndex]
    }
    setRows(_rows) {
        this.rows = _rows
        if (this.rows == 8) {
            this.coeffsTable = this.coeffsTable8
        }
        if (this.rows == 12) {
            this.coeffsTable = this.coeffsTable12
        }
        if (this.rows == 16) {
            this.coeffsTable = this.coeffsTable16
        }
        this.coefs = this.coeffsTable[this.currentCoefIndex]
    }


    betHandler(amount) {
        if (this.tokenObj.address == '0x0000000000000000000000000000000000000000') {
            this.betMainHandler(amount)
        } else {
            this.betTokenHandler(amount)
        }
    }

    async betTokenHandler(amount) {
        this.result = []

        if (!this.connected) {
            this.messageHandler({ type: 'error', msg: 'Not connected' })
            return
        }
        this.amount = amount;
        this.messageHandler({ type: 'betloader' })
        if (localStorage) {
            localStorage.setItem('betAmountPlinko', this.amount);
        }

        var betAmt = Web3.utils.toWei("" + this.amount, this.tokenObj.decimals)
        var sendData = {
            from: this.account
        }

        if (this.tokenObj.extraFeeGasLimit > 0) {
            var gasLimit = await this.contract.methods.bet(this.currentCoefIndex + 1, this.rows,this.tokenObj.address, betAmt).estimateGas(
                { from: this.account }
            )
            gasLimit = parseInt(gasLimit * this.tokenObj.extraFeeGasLimit)
            var gasPrice = await window.web3.eth.getGasPrice()
            gasPrice = parseInt(gasPrice * this.tokenObj.extraFeeGasPrice)
            sendData.gasLimit = gasLimit
            sendData.gasPrice = gasPrice
        }
        console.log(sendData)






        this.contract.methods
            .bet(this.currentCoefIndex + 1, this.rows,this.tokenObj.address, betAmt)
            .send(sendData)
            .on('transactionHash', function (hash) {
                this.messageHandler({ type: 'transactionHash', hash: hash })
            }.bind(this))
            .then(function (result) {
                this.win = Web3.utils.fromWei(result.events.Bet.returnValues.win, this.tokenObj.decimals);
                this.result = []

                for (let index = 0; index < result.events.Bet.returnValues.input[1]; index++) {
                    const element = result.events.Bet.returnValues.output[index];
                    this.result.push(element)
                }
                this.messageHandler({
                    type: 'betresult',
                    win: this.win,
                    tx_id: result.transactionHash,
                    result: this.result,
                    bet: this.amount,
                    currency:this.tokenObj.name,
                    rows: result.events.Bet.returnValues.input[1],
                    mode: result.events.Bet.returnValues.input[0] - 1
                })
            }.bind(this), function (params) {

                if (params.code) {
                    this.messageHandler({ type: 'error', msg: 'N/A' })
                    this.messageHandler({ type: 'betbutton' })
                } else {
                    this.messageHandler({ type: 'error', msg: 'User rejected code:' + params.code })
                    this.messageHandler({ type: 'betbutton' })
                }

            }.bind(this))

    }

    async betMainHandler(amount) {
        this.result = []

        if (!this.connected) {
            this.messageHandler({ type: 'error', msg: 'Not connected' })
            return
        }
        this.amount = amount;
        this.messageHandler({ type: 'betloader' })
        if (localStorage) {
            localStorage.setItem('betAmountPlinko', this.amount);
        }

        var sendData = {
            from: this.account,
            value: Web3.utils.toWei("" + this.amount, 'ether')
        }

        if (this.tokenObj.extraFeeGasLimit > 0) {
            var gasLimit = await this.contract.methods.bet(this.currentCoefIndex + 1, this.rows).estimateGas(
                {
                    from: this.account,
                    value: Web3.utils.toWei("" + this.amount, 'ether')
                }
            )
            gasLimit = parseInt(gasLimit * this.tokenObj.extraFeeGasLimit)

            var gasPrice = await window.web3.eth.getGasPrice()
            gasPrice = parseInt(gasPrice * this.tokenObj.extraFeeGasPrice)


            sendData.gasLimit = gasLimit
            sendData.gasPrice = gasPrice
        }


        console.log(sendData)





        this.contract.methods
            .bet(this.currentCoefIndex + 1, this.rows)
            .send(sendData)
            .on('transactionHash', function (hash) {
                this.messageHandler({ type: 'transactionHash', hash: hash })
            }.bind(this))
            .then(function (result) {
                this.win = Web3.utils.fromWei(result.events.Bet.returnValues.win, 'ether');
                this.result = []

                for (let index = 0; index < result.events.Bet.returnValues.input[1]; index++) {
                    const element = result.events.Bet.returnValues.output[index];
                    this.result.push(element)
                }
                this.messageHandler({
                    type: 'betresult',
                    win: this.win,
                    currency:this.tokenObj.name,
                    tx_id: result.transactionHash,
                    result: this.result,
                    bet: this.amount,
                    rows: result.events.Bet.returnValues.input[1],
                    mode: result.events.Bet.returnValues.input[0] - 1
                })
            }.bind(this), function (params) {

                if (params.code) {
                    this.messageHandler({ type: 'error', msg: 'N/A' })
                    this.messageHandler({ type: 'betbutton' })
                } else {
                    this.messageHandler({ type: 'error', msg: 'User rejected code:' + params.code })
                    this.messageHandler({ type: 'betbutton' })
                }

            }.bind(this))

    }



}

