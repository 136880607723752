import TWEEN from "@tweenjs/tween.js";
import { store } from '@/store';
import SoundManager from "@/utils/SoundManager";

export default class PlinkoBall {
    ball = { x: 0, y: 0 }
    ballMirrors = [];
    ballRadius = 5;
    pointRadius = 5;
    board = null
    betObj = null
    p0 = { x: 0, y: 0 }
    c0 = { x: 0, y: 0 }
    c1 = { x: 0, y: 0 }
    p1 = { x: 0, y: 0 }
    parentObj = null
    ballId = 0
    active = true


    init(_parentObj,_ballId) {
        this.parentObj = _parentObj
        this.ballId = _ballId

        this.ball.x = this.parentObj.width / 2
        this.ball.y = -this.parentObj.ballRadius;
    }

    setCoeffs() {
        this.ball.x = this.parentObj.board[0][1].x
        this.ball.y = -this.parentObj.ballRadius;
    }

    setBet(arr) {
        this.ball.x = this.parentObj.board[0][1].x
        this.ball.y = -this.parentObj.ballRadius;
        this.betObj = arr

        this.ballMirrors = []
        this.movePath = this.betObj.result

        var pointPos = this.parentObj.board[0][1].y - this.parentObj.pointRadius - this.parentObj.ballRadius
        new TWEEN.Tween(this.ball).
            to({ y: pointPos }, 200).
            delay(parseInt(100*Math.random())).
            easing(TWEEN.Easing.Sinusoidal.In).
            onComplete(function () {
                this.nextStep()
            }.bind(this)).
            start()



    }

    nextStep() {
        console.log("nextstep")
        if (this.betObj == null) {
            return;
        }
        new SoundManager().play({ name: 'ding' })
        for (let i = 0; i < this.parentObj.board.length; i++) {
            const row = this.parentObj.board[i];
            for (let j = 0; j < row.length; j++) {
                const element = row[j];
                element.active = false;
            }
        }


        if (this.movePath.length == 0) {
            var coeff = this.getCollidedCoeff();
            if (coeff) {
                coeff.active = true;
                setTimeout(function () {
                    coeff.active = false;
                }.bind(this), 2000)
            }
            
            this.parentObj.ballHandler(this)
            return
        }

        var point = this.getCollidedPoint();
        if (point) {
            point.active = true;
        }

        var next = this.movePath.shift()

        var nextBallPos = this.getNextBallPos(next)
        this.p0.x = this.ball.x
        this.p0.y = this.ball.y
        var linesHeight = this.parentObj.height - 20

        this.p1.x = nextBallPos.x
        this.p1.y = nextBallPos.y - this.parentObj.pointRadius * 2

        this.c0.x = this.ball.x
        this.c0.y = this.ball.y - (linesHeight / this.parentObj.rows) / 2
        this.c1.x = this.p1.x
        this.c1.y = this.p1.y - (linesHeight / this.parentObj.rows)


        var twObj = { progress: 0 }
        new TWEEN.Tween(twObj).
            to({ progress: 1 }, store.getters.getFastOn ? 100 : 300).
            easing(TWEEN.Easing.Linear.None).
            delay(parseInt(20*Math.random())).
            onUpdate(function () {
                if (this.betObj != null) {
                    var p = this.Bezier(this.p0, this.c0, this.c1, this.p1, twObj.progress);
                    this.ball.x = p.x;
                    this.ball.y = p.y;
                    this.ballMirrors.push({ x: p.x, y: p.y, opacity: 0.25 })
                }

            }.bind(this)).
            onComplete(function () {
                this.nextStep()
            }.bind(this)).
            start()
    }


    Bezier(a, b, c, d, t) {
        var point = { x: 0, y: 0 },
            mt = 1 - t,
            mt2 = mt * mt,
            mt3 = mt2 * mt;
        point.x = a.x * mt3 + b.x * 3 * mt2 * t + c.x * 3 * mt * t * t + d.x * t * t * t;
        point.y = a.y * mt3 + b.y * 3 * mt2 * t + c.y * 3 * mt * t * t + d.y * t * t * t;
        return point;
    }

    getCollidedCoeff() {
        var nearest = 1000;
        var npoint = null
        for (let j = 1; j < this.parentObj.coeffsPositions.length - 1; j++) {
            const element = this.parentObj.coeffsPositions[j];
            var a = element.x - this.ball.x;
            var b = element.y - this.ball.y;
            var c = Math.sqrt(a * a + b * b);

            if (c < nearest) {
                nearest = c;
                npoint = element;
            }
        }
        if (nearest != 1000) {
            return npoint;
        }
        return null;
    }

    getNextBallPos(side) {
        var row = []
        if (this.movePath.length > 0) {
            var currentRowIndex = this.parentObj.board.length - (this.movePath.length)
            row = this.parentObj.board[currentRowIndex]
        } else {
            row = this.parentObj.coeffsPositions
        }
        var cpos = null
        if (side == 0) {
            for (let index = 0; index < row.length; index++) {
                const element = row[index];
                if (element.x < this.ball.x) {
                    cpos = element
                }
            }
        } else {
            for (let index = row.length - 1; index >= 0; index--) {
                const element = row[index];
                if (element.x > this.ball.x) {
                    cpos = element
                }
            }
        }
        return cpos
    }

    getCollidedPoint() {
        var nearest = 1000;
        var npoint = this.parentObj.board[0][0]
        for (let i = 0; i < this.parentObj.board.length; i++) {
            const row = this.parentObj.board[i];
            for (let j = 0; j < row.length; j++) {
                const element = row[j];
                var a = element.x - this.ball.x;
                var b = element.y - this.ball.y;
                var c = Math.sqrt(a * a + b * b);

                if (c < nearest) {
                    nearest = c;
                    npoint = element;
                }
            }
        }
        if (nearest != 1000) {
            return npoint;
        }
        return null;
    }

    redrawCanvas(ctx) {
        if (ctx == null) {
            return
        }
        if (this.betObj == null) {
            return;
        }
        for (let index = 0; index < this.ballMirrors.length; index++) {
            const element = this.ballMirrors[index];
            element.opacity = element.opacity - 0.01
            if (element.opacity > 0) {
                ctx.beginPath();
                ctx.arc(element.x, element.y, this.parentObj.ballRadius, 0, Math.PI * 2);
                ctx.fillStyle = "rgba(78, 162, 198, " + element.opacity + ")";
                ctx.fill();
            }

        }



        ctx.beginPath();
        ctx.arc(this.ball.x, this.ball.y, this.parentObj.ballRadius, 0, Math.PI * 2);
        ctx.fillStyle = "#4EA2C6";
        ctx.fill();

    }


}